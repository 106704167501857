import React, { useEffect } from "react";
import logo from "./logo.svg";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { AddProductEvent } from "./pages/questionaire/add-product-event";
import { Question } from "./pages/questionaire/question";
import { NotFound } from "./pages/404";
import { Promotion } from "./pages/promotions/promotion";
import { Introduction } from "./pages/promotions/introduction";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import dotenv from "dotenv";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

const questionRoutes = [
  {
    path: "question",
    component: <Question />,
  },
  {
    path: "/question/add-product-event",
    component: <AddProductEvent />,
  },
];

const promotionRoutes = [
  {
    path: "promotion",
    component: <Promotion />,
  },
  {
    path: "/promotion/introduction",
    component: <Introduction />,
  },
];

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        {questionRoutes.map((item) => {
          return (
            <Route key={item.path} path={item.path} element={item.component} />
          );
        })}
        {promotionRoutes.map((item) => {
          return (
            <Route key={item.path} path={item.path} element={item.component} />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
