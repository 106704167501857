import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <Helmet title="Welcome | ITDA" />
      <h1 className="font-bold">Hello World!</h1>
      {/* <Link to="/question/add-product-event">설문조사</Link> */}
    </div>
  );
};
