import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

export const Question = () => {
  return (
    <>
      <Helmet title="설문조사 | ITDA" />
      <div className="font-bold">This is Question Page</div> <Outlet />
    </>
  );
};
