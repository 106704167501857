import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export const AddProductEvent = () => {
  useEffect(() => {
    window.location.href = "https://forms.gle/6oAGFksXc9CNDytK7";
  }, []);
  return (
    <div>
      <Helmet title="제품등록 설문조사 | ITDA" />
    </div>
  );
};
