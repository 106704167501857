import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { firestore } from "../../App";

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

export const Introduction = () => {
  const [docInfo, setDocInfo] = useState<DocumentData | undefined>([]);

  const bannerInfo = async () => {
    try {
      const bannerCollection = collection(firestore, "Banners");
      const bannerSnapshot = await getDocs(bannerCollection);

      const introId = bannerSnapshot.docs[0].data().banners[0].id;
      const docRef = doc(firestore, "Banners", introId);
      const docSnap = await getDoc(docRef);
      setDocInfo(docSnap.data());
    } catch (error: unknown) {
      console.log(error);
    }
  };

  useEffect(() => {
    bannerInfo();
  }, []);

  return (
    <div className="w-full">
      <Helmet title={`${docInfo?.title} | ITDA`} />
      <div className=" max-w-lg mx-auto flex-row justify-center">
        {docInfo?.pages?.map((item: any) => (
          <img src={item.url} key={item.url} />
        ))}
      </div>
    </div>
  );
};
