import React from "react";
import { Helmet } from "react-helmet-async";

export const Promotion = () => {
  return (
    <div>
      <Helmet title="프로모션 | ITDA" />
    </div>
  );
};
